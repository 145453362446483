/* @import url('https://fonts.googleapis.com/css?family=Montserrat:400,800'); */

.border-box {
    box-sizing: border-box;
}

.body {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: black;
    font-family: 'Montserrat', sans-serif;
    min-height: 100%;
}

.container-l {
    position: relative;
    width: 800px;
    max-width: 100%;
    min-height: 470px;
    background: #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 200px #5511DA;
    margin: 80px 0 0 0;

}

.sign-up,
.login {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-up {
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.login {
    width: 50%;
    z-index: 2;
}

.container-l form {
    /* background: #5511DA; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
    background: linear-gradient(141.36deg, #480D94 0.98%, #760DFF 49.28%, #1D299F 89.27%);
}

.container-l h1 {
    font-weight: bold;
    margin: 0;
}

.container-l p {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 15px 0 20px;
}

.container-l input {
    background: #eee;
    color: black;
    padding: 12px 15px;
    margin: 8px 15px;
    width: calc(100% - 30px);
    border-radius: 5px;
    border: none;
    outline: none;
}

.container-l a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.container-l button {
    color: black;
    background: #343bff;
    font-size: 15px;
    font-weight: bold;
    padding: 12px 55px;
    margin: 10px;
    border-radius: 20px;
    border: 1px solid #343bff;
    outline: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
}

.container-l button:active {
    transform: scale(0.9);
}

.sign-up button {
    color: #D9D9D9;
}

.login button {
    color: #D9D9D9;
}

#login,
#sign-up {
    background-color: transparent;
    border: 2px solid black;
}

.container-l.right-panel-active .login {
    transform: translateX(100%);
}

.container-l.right-panel-active .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container-l.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    position: relative;
    color: black;
    background: #D9D9D9;
    left: -100%;
    height: 100%;
    width: 200%;
    /* background: linear-gradient(141.36deg, #480D94 0.98%, #760DFF 49.28%, #1D299F 89.27%); */
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container-l.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-left,
.overlay-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container-l.right-panel-active .overlay-left {
    transform: translateX(0);
}

.container-l.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-media {
    margin: 7px 0;
}

.social-media a {
    height: 40px;
    width: 40px;
    margin: 0 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 50%;
    background-color: transparent;
}

.social-media a i {
    font-size: 24px;
    color: #fff;
    transition: color 0.3s ease;
}

.forget-password {
    color: #D9D9D9;
    cursor: pointer;
}

.divider {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.or-text {
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #d9d9d9;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.divider::before {
    margin-right: 10px;
}

.divider::after {
    margin-left: 10px;
}