/* Center the uploader in the viewport */
.image-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
}
/* Close Button on Uploader Box */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red; /* Red button for visibility */
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.close-button:hover {
  background: darkred; /* Darker red on hover */
}
.uploader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Black Box with White Border */
.uploader-box {
  width: 90%;
  max-width: 600px;
  background-color: #000; /* Black container */
  border: 2px solid #fff; /* White border */
  border-radius: 10px;
  position:absolute;
  z-index:2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Image Container */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

/* Individual Image Wrapper */
.image-wrapper {
  position: relative;
}

.uploaded-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #ddd;
}

/* Delete Button */
.delete-button {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Upload Box */
.upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px dashed #fff; /* White border for upload box */
  border-radius: 8px;
  cursor: pointer;
}

.plus-icon {
  font-size: 24px;
  color: #fff; /* White plus icon */
  text-align: center;
}

/* Submit Button */
.submit-button {
  background-color: #fff; /* White button */
  color: #000; /* Black text */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
}

.submit-button:hover {
  background-color: #ddd; /* Slight gray hover effect */
}
