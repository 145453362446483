@import url('https://fonts.googleapis.com/css?family=Poppins');

.why-abiv-container {
  background: linear-gradient(180deg, #010B78 0%, #000000 100%);
  color: white;
  padding: 3rem 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
}

.content-container {
  max-width: 130rem;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins';
}

.why-abiv-container .content-container .title {
  font-size: 3.4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Poppins';
}

.why-abiv-container .content-container .description {
  font-size: 1.5rem;
  margin-bottom: 4rem;
  text-align: center;
}

.grid-container {
  display: grid;
  width: 80%;
  margin-left: 10%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
}

.ccard {
  background-color: #084FC7;
  color: white;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.image {
  width: 50%;
  height: auto;
  object-fit: cover;
}

.ccard-content {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.ccard-title {
  font-size: 2.0rem;
  font-weight: 600; /* Higher value for more boldness */
  margin-left: 10%;
  margin-bottom: 10px;
  margin-top: 30px;
}

.ccard-description {
  font-size: 1.0rem;
  font-weight: 300;
  margin-top: 1rem;
  margin-left: 12%;
  margin-right: 8%;
}

.ccard-link {
  color: white;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.4rem;
  margin-top: 18px;
  margin-left: 10%;
  margin-bottom: 30px;
  font-family: 'Poppins';
  font-weight: 500;
}

.ccard-left {
  flex-direction: row;
}

.ccard-right {
  flex-direction: row-reverse;
}

@media (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .ccard-title {
    font-size: 1.8rem;
  }

  .ccard-description {
    font-size: 1.2rem;
  }

 

  .ccard-image {
    width: 50%;
    height: auto;
  }

  .ccard-link {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .ccard-title {
    font-size: 1.6rem;
  }

  .ccard-description {
    font-size: 1.0rem;
  }

 

  .ccard-image {
    width: 50%;
    height: auto;
  }

  .ccard-link {
    font-size: 1.0rem;
  }
}

@media (max-width: 480px) {
  .why-abiv-container {
    padding: 2rem 1rem;
  }

  .content-container {
    padding: 0 1rem;
  }

  .why-abiv-container .content-container .title {
    font-size: 2.4rem;
  }

  .why-abiv-container .content-container .description {
    font-size: 1.2rem;
  }

  .ccard-title {
    font-size: 1.0rem;
  }

  .ccard-description {
    font-size: 0.6rem;
  }

  .ccard-link {
    font-size: 0.6rem;
  }
}
