.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
.loadingimg{
  width: 150px;
  height: 150px;
}
.mosaic-container {
  position: relative;
  overflow: hidden;
}

.xtiles {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.xtiles.visible {
  opacity: 1;
}

.xmosaic-tile {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.xmosaic-tile.show {
  opacity: 1;
}
.tiles {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.tiles.visible {
  opacity: 1;
}

.mosaic-tile {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transform: scale(0.1);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
}

.mosaic-tile.show {
  opacity: 1;
  transform: scale(1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* styles.css */
.flip-animation {
  animation: flip 1s forwards;
}

@keyframes flip {
  0% {
    transform: perspective(1000px) rotateY(0);
    opacity: 1;
  }
  50% {
    transform: perspective(1000px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: perspective(1000px) rotateY(0);
    opacity: 1;
  }
}
/* App.css */

.video-container {
  transition: opacity 7s ease-in-out;
}

.fade-out {
  opacity: 0.75;
}

.fade-in {
  opacity: 1;
}
.flip-book {
  transform-style: preserve-3d;
  transition: transform 11s;
}

.flipping .flip-book {
  transform: rotateY(-180deg);
}

.video-page {
  
  backface-visibility: hidden;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}
