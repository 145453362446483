.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #ffffff #4a5568;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #4a5568;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #4a5568;
}.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #ffffff #4a5568;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #4a5568;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #4a5568;
}