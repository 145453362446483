/* ChalkWriting.css */
.chalkboard {
  background: url('./Blackboard.png') no-repeat center center; /* Ensure the background image is centered and does not repeat */
  background-size: cover; /* Ensure the background image fits within the container */
  color: #eee; /* Light chalk color for the text */
  font-family: 'Courier New', Courier, monospace; /* Monospace font to resemble chalk writing */
  font-size: 40px; /* Adjust font size as needed */
  padding: 20px;
  border: 1px solid #eee;
  width: 100%;
  margin: auto;
  position: relative; /* Position relative for the chalk effect */
  overflow-y: scroll; /* Allow vertical scrolling */
  height: 100%; /* Adjust height to fit content */
  white-space: pre-wrap; /* Preserve spaces and newlines */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  scrollbar-width: none; /* Hide the scrollbar in Firefox */
  -ms-overflow-style: none; /* For Internet Explorer */
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.chalkboard::-webkit-scrollbar {
    display: none;
}
.green{
  color:aquamarine;
}

.typing {
    position: relative;
    display: inline-block;
    white-space: pre-wrap; /* Preserve new lines */
    margin-top: 5px; /* Adjust spacing between lines if needed */
    overflow-y: auto;
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
    -ms-overflow-style: none; /* For Internet Explorer */
  }
  .typing::-webkit-scrollbar {
    display: none;
}
  .chalk-writing {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.2); /* Chalk mark color */
    clip-path: inset(0 0 100% 0); /* Start with no visible chalk */
    animation: writing 5s steps(100, end) forwards; /* Adjust animation duration */
  }
  .red {
    color: red;
  }
  
  .box {
    border: 2px solid white;
    padding: 5px;
  }
  .bold{
    font-weight: bold;
  }
  .blue{
    color:aqua;
  }
  @keyframes writing {
    to {
      clip-path: inset(0 0 0 0); /* Reveal chalk writing */
    }
  }
  