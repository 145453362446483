@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400..700&display=swap');

.body {
  background: linear-gradient(180deg, #010b78 0%, #000000 100%);
  margin: 0;
  height: 100vh; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-div {
  margin: 0 auto;
  width: 90%;
  max-width: 1197px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.first-div {
  width: 100%;
  background-color: #ffffffcc;
  border-radius: 37.57px 37.57px 0 0;
  border-bottom: 8vh solid #4C60A5;
  z-index: 1;
  overflow-y: auto;
}

.second-div {
  width: 100%;
  border-radius: 0 0 37.57px 37.57px;
  background-color: #ffffffcc;
  font-family: 'Jost', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
}

.content {
  font-family: 'Advent Pro', sans-serif;
  font-size: 2vh;
  font-weight: 500;
  flex-shrink: 0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2vh;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #5d5d5f;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b5b5b5;
  border-radius: 1.5vh;
  border: 0.3vh solid #e0e0e0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #899499;
}

.Time {
  color: #035c39;
  font-size: 5vh;
  font-weight: 800;
}

.upload {
  background-color: #001f9f;
  height: 10vh;
  width: 10vh;
  box-shadow: 0px 0.4vh 0.4vh 0px #00000040;
  border-radius: 2vh;
  align-items: center;
  justify-content: center;
  display: flex; 
}

.submit {
  background-color: #0875f7;
  border-radius: 1.15vh;
  color: #ffffff;
  width: 16.9vh;
  height: 8vh;
  padding: 1vh;
  box-shadow: 0px 0.46vh 0.46vh 0px #00000040;
  font-family: 'Afacad', sans-serif;
  font-size: 2.2vh;
  text-align: center;
  margin-top: 1vh;
}

.submit:hover,
.upload:hover {
  transform: translateY(-0.3vh);
  transition-duration: 0.3s;
}

.responses {
  margin-bottom: 3vh;
}

.main-header {
  display: inline-flex;
  color: white;
}

@media (min-width: 768px) {
  .main-div {
    flex-direction: row;
    height: 80vh;
  }

  .first-div {
    width: 70%;
    border-radius: 37.57px 0 0 37.57px;
    overflow-y: auto;
  }

  .second-div {
    width: 30%;
    border-radius: 0 37.57px 37.57px 0;
  }
}

@media (max-width: 767px) {
  .main-div {
    flex-direction: row;
    overflow-y: auto;
  }

  .first-div {
    width: 100%;
    border-radius: 0;
    border-bottom: none;
    height:73vh
  }

  .second-div {
    width: 100%;
    border-radius: 0;
    height:73vh;
  }
}
