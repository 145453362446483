.forgeet-password {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transition: all 0.6s ease-in-out;
    width: 50%;
    z-index: 1;
}

.forgeet-password button{
    color: #d9d9d9;
    padding: 10px 20px;
    margin: 10px;
}
