.headline {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
}

.video-section {
    max-width: 70%;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 11px;
    border: 4px solid #ffffff;
    overflow: hidden;
}

.video-section video {
    width: 100%;
    height: auto;
    object-fit: cover;
}