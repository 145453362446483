@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poly:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
*{
  margin: 0;
  padding: 0;
  ::selection{
    color: red;
  }
  ::-webkit-scrollbar{
    width: 7px;
    

  }
  ::-webkit-scrollbar-track{
    background: white;
  }
  ::-webkit-scrollbar-thumb{
    background: black;
    border-radius: 20px;
  }
}

#root{
  min-height: 100vh;
 
  background  : linear-gradient(#010B78 24%, #000000 84%);
  color: white;
}
.navbar-background{
  background: #000622;

}
.video-page-background{
  background:  linear-gradient(#010B78 24%, #000000 124%);
  background-repeat: no-repeat;
}
.background{
  background:  linear-gradient(#000D3F 0%, #000000 100%);
  background-repeat: no-repeat;
}
.radial-background{
  background: radial-gradient(62.89% 62.89% at 50% 50%, #27B5FF 0%, #000000 100%) ;

}
.quiz_gradiant{
  background: linear-gradient(90deg, #010B78 0%, #6C009F 100%);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

}
.custom-radio {
  width: 1.5rem;
  height: 1.5rem;
  transform: scale(1);
  margin-right: 0.5rem;
  appearance: none;
  border: 2px solid #9b5de5; 
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-radio:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #9b5de5; 
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.button-1{
  background: linear-gradient(159.81deg, #00E5FF -34.87%, #FFFFFF 151.64%);

}
.button-2{
  background: linear-gradient(169.02deg, #B2EBF2 -58.33%, #D1C4E9 60.57%);

}
.button-3{
  background: linear-gradient(152.01deg, #B2EBF2 9.05%, #D1C4E9 63.77%, #F8BBD0 92.79%);

}

.pricing{
  background: white;
}
